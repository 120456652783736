import { inject, Injectable } from "@angular/core";
import {
  Observable,
  BehaviorSubject,
  Subject,
  AsyncSubject,
  throwError,
  lastValueFrom,
} from "rxjs";
import { environment } from "src/environments/environment";
import { catchError, map, tap } from "rxjs/operators";
import {EmptyUser, User} from "src/app/shared/model/user";
import { UpdateUser } from "src/app/shared/model/update-user";
import { Register } from "src/app/shared/model/register";
import { ApiService } from "src/app/shared/service/http.service";
import { AuthService } from "src/app/core/auth/authentication.service";
import {Friendship} from "../../shared/model/friendship";

@Injectable({
  providedIn: "root",
})
export class UserService{
  public User$: BehaviorSubject<User> =  new BehaviorSubject<User>(EmptyUser());
  public currentUser: Observable<User> = this.User$.asObservable();

  authService = inject(AuthService)
  httpService = inject(ApiService);

  private userRoutes = environment.api.user;

  private userAsyncSubject = new AsyncSubject<User>();
  private randomUserSubject = new Subject<User>();

  getFriends(id?: number){
    return this.httpService.Get<Friendship[]>(this.userRoutes.friends, { params: { id }});
  }

  setUserAsyncSubject(user: User) {
    this.userAsyncSubject.next(user);
    this.userAsyncSubject.complete();
  }

  getUserAsyncSubject(): Observable<User> {
    return this.userAsyncSubject
      .asObservable()
      .pipe(map((value) => {
        return value!;
      }));
  }

  async refreshUserObject(): Promise<User> {
    const user = await lastValueFrom(this.httpService.Get<User>(this.userRoutes.getUser));
    this.setUser(user);
    return user;
  }

  getRandomUserSubject(): Observable<User> {
    return this.randomUserSubject.asObservable();
  }

  getUserInfo(userId?: number) {
    const params = userId !== undefined ? { id: userId } : {};
    this.httpService.Get<User>(this.userRoutes.getUser, { params: params }).subscribe((data) => {
      if (userId) {
        this.randomUserSubject.next(data);
      } else {
        this.userAsyncSubject.next(data);
        this.userAsyncSubject.complete();
      }
    });
  }

  returnUserInfo(id?: number): Observable<User> {
    return id ? this.httpService.Get(this.userRoutes.getUser, { params: { id } }) : this.httpService.Get(this.userRoutes.getUser);
  }

  setUserToNull() {
    this.userAsyncSubject.next(EmptyUser());
    this.userAsyncSubject.complete();
  }
  /**
   * Set logged in user.
   */
  public setUser(user: User) {
    this.User$.next(user);
  }

  /**
   * Get current value from Observable without subscribing (just want value one time).
   */
  public getCurrentUser() {

    return this.User$.pipe(map((value) => {
      return value;
    }));
  }

  /**
   * A call to update the customer profile with new data. THis method returns an observable, however, the
   * result is also published in the Observable userSource. So if the client already subscribes the new User
   * data by subscribing to userSource, that client will be notified once this operation has succeeded.
   */
  updateCustomerProfile(updateUser: UpdateUser): Observable<User> {
    return this.httpService
      .Post<User>(this.userRoutes.update, updateUser)
      .pipe(
        tap((res) => {
          if (res) {
            this.setUser(res);
          }
        }),
        catchError((error) => throwError(error))
      );
  }


  getUserData(socialSecurityNr: string): Observable<Register> {
    return this.httpService.Get<User>(this.userRoutes.getData, { params: { socialSecurityNr: socialSecurityNr } });
  }

  public addUser(registerData: Register): Observable<Register> {
    return this.httpService.Post<Register>(this.userRoutes.register, registerData);
  }

  updateUser(registerData: Register): Observable<User> {
    return this.httpService.Post<User>(this.userRoutes.update, registerData);
  }
}
