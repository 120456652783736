import { Address } from "src/app/shared/model/address";
import { FriendshipStatus } from "./friendship";

export interface User {
  id: number;
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  password: string;
  phoneNumber: string;
  mobilePhoneNumber: string;
  agreeMarketing: boolean;
  subscribeToEmailNotification: boolean;
  address: Address;
  gender: string;
  friendship: FriendshipStatus | null;
  havePet: boolean;
  petNum: number;
  petType: string;
  profileImage: string
}

export const EmptyUser = () : User => ({
    id: 0,
    socialSecurityNumber: "",
    firstName: "",
    lastName: "",
    fullName: "",
    email: "",
    password: "",
    phoneNumber: "",
    mobilePhoneNumber: "",
    gender: "",
    havePet: false,
    petNum: 0,
    petType: "",
    agreeMarketing: false,
    subscribeToEmailNotification: false,
    profileImage: "",
    address: Address.empty(),
    friendship: null,
  })
