
<!-- The Modal -->
<div id="myModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content">
      <div class="modal-header">
        <h3>{{config.data.title}}</h3>
        <span class="close" (click)="onClose()">&times;</span>
      </div>
      <div class="modal-body">
        <p>{{config.data.message}}</p>
      </div>
      <div class="modal-footer">
   
        <button type="button" class="btn btn-outline-dark" (click)="onAccept()">{{config.data.firstButton}}</button>
        <button type="button" class="btn btn-outline-dark" (click)="onClose()">{{config.data.secondButton}}</button>
       
      </div>
    </div>
  
  </div>